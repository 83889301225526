/* RoUser.css */
.content-row .col {
    max-width: 95%;
    margin: auto;
    /* 중앙 정렬 */
    padding-bottom: 1rem;
    /* 하단 여백 추가 */
}

/* 컨텐츠 제목 스타일링 */
.content-row .col h2 {
    margin-bottom: 1rem;
    /* 제목과 내용 사이의 여백 */
}

/* 버튼 스타일링 */
.content-row .col button {
    margin-bottom: 1rem;
    /* 버튼과 다음 내용 사이의 여백 */
}

.period-button {
    margin-right: 10px;
    /* 오른쪽 여백 10px 추가 */
}