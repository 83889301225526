/* LoginPage.css */
.login-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /* Align items to the start of the container */
    height: 100vh;
    background-color: #f5f5f5;
    padding-top: 20vh;
    /* Set a fixed padding from the top */
}

.login-card {
    padding: 2rem;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    /* Maximum width of the form */
    text-align: center;
    /* Rest of your styles for .login-card */
}

.login-card h1 {
    color: #202124;
    margin-bottom: 1.5rem;
}

.login-card .form-group {
    margin-bottom: 1rem;
}

.login-card .btn-primary {
    background-color: #1a73e8;
    /* Google blue */
    border-color: #1a73e8;
    width: 100%;
    /* Full-width button */
}

.login-card .alert-danger {
    margin-top: 1rem;
}