/* 모바일 화면용 최소 높이 설정 */
.list-group-item {
    min-height: 48px;
    /* 터치 가능 영역을 확보 */
}

/* CSS 파일에 추가 */
.list-group-custom {
    background-color: #003366 !important;
    /* 우선 순위를 높여 적용 */
    color: #ffffff !important;
    /* 텍스트 색상을 흰색으로 설정 */
}

/* 모바일 화면에서 가득 차도록 Col 설정 */
@media (max-width: 767px) {
    .list-group-col {
        max-width: 100%;
        /* 모바일 화면에서는 리스트가 전체 너비를 사용하도록 */
        flex: 0 0 100%;
        /* flex 속성을 이용해 너비를 100%로 설정 */
        margin-right: 0;
        /* 오른쪽 여백 제거 */
    }

    .main-content-col {
        max-width: 100%;
        /* 메인 내용도 전체 너비를 사용하도록 */
        flex: 0 0 100%;
        /* 너비 100% */
    }
}

/* 메뉴 버튼 위치 조정 */
.navbar-toggler {
    float: left;
    /* 메뉴 버튼을 왼쪽으로 이동 */
}